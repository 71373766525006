import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm) => {
    try {
        Store.commit('mutateCommonProperties', {
            loading: true
        })
        if (i18n.locale === 'bn') {
            pdfMake.vfs = pdfFontsBn.pdfMake.vfs
        } else {
            pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [{
                columns: reportHeadData.reportHeadColumn
            },
            { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
            { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
            { text: reportHeadData.address, style: 'address', alignment: 'center' },
            { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        const bodySeperate = [
            [
                { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'th' },
                { text: vm.$n(data.circular_memo_no), style: 'td' },
                { text: vm.$t('elearning_config.fiscal_year'), style: 'th' },
                { text: vm.$i18n.locale === 'en' ? data.year : data.year_bn, style: 'td' }
            ]
          ]
          if (Store.state.Auth.activeRoleId === 1 || Store.state.Auth.authUser.org_id === 1 || Store.state.Auth.authUser.org_id === 12) {
            bodySeperate.push([
                { text: vm.$t('org_pro.organization'), style: 'th' },
                { text: (i18n.locale === 'en') ? data.org : data.org_bn, style: 'td' },
                { text: vm.$t('org_pro.office_type'), style: 'th' },
                { text: vm.$i18n.locale === 'en' ? data.office_type : data.office_type_bn, style: 'td' }
            ]
            )
          }
          bodySeperate.push(
            [
                { text: vm.$t('org_pro.office_namel'), style: 'th' },
                { text: (i18n.locale === 'en') ? data.office : data.office_bn, style: 'td' },
                { text: vm.$t('elearning_config.training_type'), style: 'th' },
                { text: vm.$i18n.locale === 'en' ? data.traning_type : data.traning_type_bn, style: 'td' }
            ],
            [
                { text: vm.$t('elearning_config.training_category'), style: 'th' },
                { text: (i18n.locale === 'en') ? data.traning_category : data.traning_category_bn, style: 'td' },
                { text: vm.$t('elearning_config.training_title'), style: 'th' },
                { text: (i18n.locale === 'en') ? data.traning_title : data.traning_title_bn, style: 'td' }
            ],
            [
                { text: vm.$t('globalTrans.mobile'), style: 'th' },
                { text: (i18n.locale === 'en') ? data.mobile : data.mobile, style: 'td' },
                { text: vm.$t('elearning_tim.trainee_triner_name'), style: 'th' },
                { text: (i18n.locale === 'en') ? data.name : data.name_bn, style: 'td' }
            ],
            [
                { text: vm.$t('elearning_tim.disciplinary_action'), style: 'th' },
                { text: (i18n.locale === 'en') ? data.disciplinary_action : data.disciplinary_action_bn, style: 'td', colSpan: 3 },
                {},
                {}
            ]
          )
        // if (reportHeadData.projectName) {
            // pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        // }
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['*', '*', '*', '*'],
                body: bodySeperate
            }
        })
        var docDefinition = {
            content: pdfContent,
            pageSize: 'A4',
            pageOrientation: 'Portrait',
            watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
            styles: {
                th: {
                    fillColor: '',
                    fontSize: 10,
                    bold: true,
                    margin: [3, 3, 3, 3]
                },
                td: {
                    fontSize: 10,
                    margin: [3, 3, 3, 3]
                },
                header2: {
                    fontSize: 15,
                    bold: true,
                    margin: [15, 15, 25, 10]
                },
                header: {
                    fontSize: 14,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 0, 0, 0]
                },
                org: {
                    fontSize: 13,
                    bold: true,
                    alignment: 'center',
                    margin: [0, -25, 0, 0]
                },
                address: {
                    fontSize: 11,
                    margin: [0, 0, 0, 5]
                },
                hh: {
                    fontSize: 13,
                    bold: true,
                    margin: [10, 10, 25, 20]
                },
                header11: {
                    fontSize: 13,
                    bold: true,
                    margin: [15, 10, 10, 10]
                },
                transport: {
                    fontSize: 10,
                    margin: [10, 10, 0, 20]
                },
                headerPort1: {
                    fontSize: 10,
                    margin: [0, 20, 0, 0]
                },
                headerPort: {
                    fontSize: 10,
                    margin: [0, 4, 0, 15]
                },
                header3: {
                    fontSize: 9,
                    margin: [0, 0, 0, 4]
                },
                tableSubHead: {
                    margin: [0, 5, 0, 15]
                },
                krishi: {
                    margin: [0, -15, 0, 15],
                    alignment: 'center'
                }
            }
        }
        pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
        if (error) {}
    }
    Store.commit('mutateCommonProperties', {
        loading: false
    })
}
export default {
    exportPdfDetails
}
